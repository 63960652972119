import * as actionTypes from './actionTypes';

export const updateSettings = (data) => {
    return {
        type: actionTypes.UPDATE_SETTINGS,
        data
    }
}

export const updateSettingsStart = () => {
  return {
    type: actionTypes.UPDATE_SETTINGS_START
  }
}

export const updateSettingsSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_SETTINGS_SUCCESS,
    data
  }
}

export const updateSettingsFail = (err) => {
  return {
    type: actionTypes.UPDATE_SETTINGS_FAIL,
    err
  }
}

export const readSettings = (data) => {
    return {
        type: actionTypes.READ_SETTINGS
    }
}

export const readSettingsStart = () => {
  return {
    type: actionTypes.READ_SETTINGS_START
  }
}

export const readSettingsSuccess = (data) => {
  return {
    type: actionTypes.READ_SETTINGS_SUCCESS,
    data
  }
}

export const readSettingsFail = (err) => {
  return {
    type: actionTypes.READ_SETTINGS_FAIL,
    err
  }
}

export const tppMaintenance = (status) => {
  return {
    type: actionTypes.TPP_MAINTENANCE,
    status
  }
}

export const tppMaintenanceStart = () => {
  return {
    type: actionTypes.TPP_MAINTENANCE_START
  }
}

export const tppMaintenanceSuccess = (result) => {
  return {
    type: actionTypes.TPP_MAINTENANCE_SUCCESS,
    result
  }
}

export const tppMaintenanceFail = (err) => {
  return {
    type: actionTypes.TPP_MAINTENANCE_FAIL,
    err
  }
}

export const tppMaintenanceCheck = () => {
  return {
    type: actionTypes.TPP_MAINTENANCE_CHECK
  }
}

export const tppMaintenanceCheckStart = () => {
  return {
    type: actionTypes.TPP_MAINTENANCE_CHECK_START
  }
}

export const tppMaintenanceCheckSuccess = (response) => {
  return {
    type: actionTypes.TPP_MAINTENANCE_CHECK_SUCCESS,
    response
  }
}

export const tppMaintenanceCheckFail = (err) => {
  return {
    type: actionTypes.TPP_MAINTENANCE_CHECK_FAIL,
    err
  }
}
